@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 1rem;
  transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
}

.containerLoading {
  background: white;
  color: black;
}

.gradientPurple {
  background: linear-gradient(to bottom right, #9333ea, #3b82f6);
}

.gradientBlue {
  background: linear-gradient(to bottom right, #1e3a8a, #3b82f6);
}

.gradientBrown {
  background: linear-gradient(to bottom right, #78350f, #b45309);
}

.gradientGreen {
  background: linear-gradient(to bottom right, #065f46, #34d399);
}

.gradientYellow {
  background: linear-gradient(to bottom right, #92400e, #fbbf24);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.logo {
  height: auto;
  object-fit: contain;
}

.logoFallback {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bold;
}

.subtitle {
  font-family: 'Permanent Marker', cursive;
  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: center;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: 4rem;
  width: 100%;
}

.contentShape {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  margin: 0 auto;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.25rem;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.content {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  width: 100%;
}

.contentVisible {
  opacity: 1;
}

.homeLink {
  margin-top: 1rem;
}

.homeLink a {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: white;
}

.homeLink a:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.homeLink a span {
  margin-left: 0.5rem;
  font-size: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}